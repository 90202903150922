export default {
  'basic': '基本設定',
  'advanced': '進階設定',
  'lab': '實驗性功能',
  'save': '儲存並套用',
  'save-success-message': '偏好設定儲存成功',
  'save-fail-message': '偏好設定儲存失敗',
  'discard': '捨棄',
  'startup': '啟動',
  'open-at-login': '開機自動啟動',
  'keep-window-state': '恢復上次退出時視窗的大小和位置',
  'auto-resume-all': '自動繼續未完成的任務',
  'default-dir': '預設下載路徑',
  'mas-default-dir-tips': '因 App Store 的沙盒權限限制，建議將預設下載路徑設定為您的「下載」資料夾',
  'transfer-settings': '傳輸設定',
  'transfer-speed-upload': '上傳限制',
  'transfer-speed-download': '下載限制',
  'transfer-speed-unlimited': '無限制',
  'bt-settings': 'BT 設定',
  'bt-save-metadata': '將磁力連結中繼資料儲存為種子檔案',
  'bt-auto-download-content': '自動開始下載磁力連結、種子的檔案',
  'bt-force-encryption': '強制 BT 加密',
  'keep-seeding': '持續做種，直到手動停止',
  'seed-ratio': '做種分享率',
  'seed-time': '做種時間',
  'seed-time-unit': '分鐘',
  'task-manage': '任務管理',
  'max-concurrent-downloads': '最多可同時下載的任務數',
  'max-connection-per-server': '每台伺服器的最大連線數',
  'new-task-show-downloading': '新增任務後自動顯示下載頁面',
  'no-confirm-before-delete-task': '刪除任務之前無需確認',
  'continue': '斷點續傳',
  'task-completed-notify': '下載完成後通知',
  'auto-purge-record': '當結束程式時自動清除下載紀錄',
  'ui': '使用者介面',
  'appearance': '外觀',
  'theme-auto': '自動',
  'theme-light': '淺色',
  'theme-dark': '深色',
  'auto-hide-window': '自動隱藏視窗',
  'run-mode': '運作模式', //macOS only feature
  'run-mode-standard': '標準應用程式',
  'run-mode-tray': '托盤應用程式',
  'run-mode-hide-tray': '隱藏托盤應用程式',
  'tray-speedometer': '托盤顯示即時速度',
  'show-progress-bar': '顯示下載進度條',
  'language': '語言',
  'change-language': '更改語言',
  'hide-app-menu': '隱藏選單列（僅支援 Windows 和 Linux）',
  'proxy': 'Proxy',
  'enable-proxy': '使用 Proxy',
  'proxy-bypass-input-tips': '忽略這些主機與網域的 Proxy 設定，一列一個',
  'proxy-scope-download': '下載',
  'proxy-scope-update-app': '更新應用程式',
  'proxy-scope-update-trackers': '更新 Tracker 列表',
  'proxy-tips': '查看 Proxy 配置手冊',
  'bt-tracker': 'Tracker 伺服器',
  'bt-tracker-input-tips': 'Tracker 伺服器，一列一個',
  'bt-tracker-tips': '推薦使用：',
  'sync-tracker-tips': '從源伺服器同步',
  'auto-sync-tracker': '每天自動更新 Tracker 伺服器列表',
  'port': '監聽連接埠',
  'bt-port': 'BT 監聽連接埠',
  'dht-port': 'DHT 監聽連接埠',
  'security': '安全性',
  'rpc': 'RPC',
  'rpc-listen-port': 'RPC 監聽埠',
  'rpc-secret': 'RPC 授權密鑰',
  'rpc-secret-tips': '查看說明手冊',
  'developer': '開發者',
  'user-agent': 'User-Agent',
  'mock-user-agent': '偽裝 User Agent',
  'aria2-conf-path': '內建的 aria2.conf 路徑',
  'app-log-path': '應用程式記錄檔位置',
  'download-session-path': '下載工作階段路徑',
  'session-reset': '重設下載工作階段',
  'session-reset-confirm': '您確定要重設下載工作階段嗎？',
  'factory-reset': '還原出廠預設值',
  'factory-reset-confirm': '您確定要還原為出廠預設值嗎？',
  'lab-warning': '⚠️開啟實驗性功能可能會造成程式當機或資料遺失，請自行斟酌！',
  'download-protocol': '下載協定',
  'protocols-default-client': '設定為以下通訊協定的預設客戶端',
  'protocols-magnet': '磁力連結 [ magnet:// ]',
  'protocols-thunder': '迅雷連結 [ thunder:// ]',
  'browser-extensions': '瀏覽器擴充功能',
  'baidu-exporter': '百度網盤助手',
  'browser-extensions-tips': '社群提供的瀏覽器擴充功能「不保證可用性」，',
  'baidu-exporter-help': '點此檢視使用說明',
  'auto-update': '自動更新',
  'auto-check-update': '自動檢查更新',
  'last-check-update-time': '上次檢查更新時間',
  'not-saved': '設定未儲存',
  'not-saved-confirm': '已修改的設定將會丟失，確定要離開嗎？'
}
